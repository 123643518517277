import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import './TextField.scss';


const TextField = ({ name, value, error, type, onChange, placeholder, isDisable, customClass
}) => (
    <Fragment>
      <input
        className={`form-control ${error ? 'is-invalid' : ''} ${customClass}`}
        type={type || 'text'}
        name={name}
        value={value}
        placeholder={placeholder || ''}
        onChange={onChange}
        disabled={isDisable}
      />
      {error && <span className="invalid-feedback">{error[0]}</span>}
    </Fragment>
  );

TextField.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.instanceOf(Array),
  type: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  customClass: PropTypes.string,
  isDisable: PropTypes.bool,
};

export default TextField;

// Ex: how to use
// <TextField
//   error={formErrors['amount']}
//   onChange={this.handleInputChange}
//   value={amount}
//   name="amount"
// />
